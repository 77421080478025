import React from "react";
import "./AboutUs.css";
import { Canvas } from "@react-three/fiber";
import Scene from "./Scene";

function AboutUsComp() {
	return (
		<div>
			<Canvas style={{ height: "50vh"}} onCreated={(state)=>state.gl.setClearAlpha(0)}>
				<Scene />
			</Canvas>
		</div>
	);
}

export default AboutUsComp;
