import React from "react"
import "./Privacy.css"
function Privacy() {
  return (
    <div className="privacy_main_div">
      <h1>Privacy Policy and Terms of Service</h1>
      <h2>Privacy Policy</h2>
      <p>
        Welcome to Stitches Socials&apos; Privacy Policy. This document outlines how we collect, use, disclose, and protect your personal information when you
        interact with our website and services. We are committed to maintaining the privacy and security of your information.
      </p>
      <h3>Information We Collect</h3>
      <p>
        We may collect personal information such as your name, email address, phone number, and any information you provide through our client questionnaire.
        Additionally, we may automatically collect certain non-personal information when you visit our website, such as your IP address, browser type, and
        device information.
      </p>
      <h3>How We Use Your Information</h3>
      <p>
        We use the information collected to provide and improve our services, tailor our offerings to your needs, and communicate with you effectively. This
        includes responding to your inquiries, delivering our services, and sending relevant updates or promotional materials.
      </p>
      <h3>Disclosure of Information</h3>
      <p>
        We do not share your personal information with third parties except as necessary to provide our services or as required by law. We may share
        non-personal information for analytics and marketing purposes, but this information does not identify you personally.
      </p>
      <h3>Data Security</h3>
      <p>
        We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. Our website and data
        storage are secured with industry-standard encryption and security protocols.
      </p>
      <h3>Your Choices</h3>
      <p>
        You have the right to access, correct, or delete your personal information. You can also choose to opt out of receiving promotional materials from us.
        If you have any questions or requests regarding your personal information, please contact us at us@stitchessocials.com.
      </p>
      <h3>Client Questionnaire</h3>
      <p>
        The information provided in the client questionnaire is used solely for the purpose of tailoring our services to your needs. The information you share
        helps us understand your goals and objectives, target audience preferences, and other relevant details necessary for effective social media strategies.
        This information is kept confidential and is not shared with third parties without your consent.
      </p>
      <h3>Third-Party Services</h3>
      <p>
        Our website may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties. We encourage
        you to review the privacy policies of any third-party websites you visit.
      </p>
      <h3>Changes to this Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any modifications will be effective upon
        posting the updated policy on our website.
      </p>
      <h3>Contact Us</h3>
      <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at us@stitchessocials.com</p>
      <p>Effective Date: Monday, 14th of August 2023.</p>
      <p>By continuing to use our website and services, you acknowledge that you have read and understood this Privacy Policy.</p>
      <h2>Terms and Services</h2>
      <h2>Terms of Service</h2>
      <p>
        Welcome to Stitches Socials&rsquo; Terms of Service. These terms outline the rules and guidelines for using our website and services. By accessing or
        using our website, you agree to comply with these terms. Please read them carefully.
      </p>
      <h3>Use of Website</h3>
      <p>
        You agree to use our website and services for lawful purposes only. You must not use our website to engage in any activities that violate local, state,
        or international laws and regulations.
      </p>
      <h3>Intellectual Property</h3>
      <p>
        All content, including text, images, graphics, videos, and other materials on our website, is protected by copyright and other intellectual property
        laws. You may not reproduce, distribute, modify, or create derivative works from any content without our prior written consent.
      </p>
      <h3>Client Questionnaire</h3>
      <p>
        The information provided in the client questionnaire is used to tailor our services to your needs. The information you provide helps us understand your
        goals, target audience, and other relevant details necessary for effective social media strategies. This information is kept confidential and is not
        shared with third parties without your consent.
      </p>
      <h3>Privacy</h3>
      <p>
        Your use of our website and services is also governed by our Privacy Policy. Please review the policy to understand how we collect, use, and protect
        your personal information.
      </p>
      <h3>Disclaimer</h3>
      <p>
        The content on our website is provided for informational purposes only. We make no guarantees about the accuracy, completeness, or reliability of any
        information presented. You use the information on our website at your own risk.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of our website and
        services.
      </p>
      <h3>Third-Party Links</h3>
      <p>
        Our website may contain links to third-party websites or services. We do not endorse or control these third-party sites, and we are not responsible for
        their content or practices. Your use of third-party websites is at your own risk.
      </p>
      <h3>Changes to Terms</h3>
      <p>
        We may update these Terms of Service from time to time. Any modifications will be effective upon posting the updated terms on our website. Your
        continued use of our website after changes have been posted constitutes your acceptance of the modified terms.
      </p>
      <h3>Contact Us</h3>
      <p>If you have any questions, concerns, or requests regarding our Terms of Service, please contact us at us@stitchessocials.com.</p>
      <p>Effective Date: Monday, 14th of august 2023.</p>
      <p>By using our website and services, you acknowledge that you have read, understood, and agreed to these Terms of Service.</p>
    </div>
  )
}

export default Privacy
