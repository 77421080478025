import React from "react"
import "./Footer.css"
import logo from "../../Assets/logo_dark.png"
import { useNavigate } from "react-router-dom"
function Footer() {
  const navigate = useNavigate()
  return (
    <div className="footer_main_div">
      <div className="footer_div_left">
        <img src={logo} className="footer_logo" alt="logo" />
        <a className="footer_textleft" href="https://www.google.com/maps/search/?api=1&query=33.930328,35.586838" target="_blank">
          Designing Connections
          <br />
          33.930328, 35.586838
        </a>
      </div>
      <div className="footer_div_right">
        <div className="footer_textright">
          <div className="footer_text_header">Quick Links</div>
          <div className="footer_text" onClick={() => navigate("/")}>
            Home
          </div>
          <div className="footer_text" onClick={() => navigate("/packages")}>
            Packages
          </div>
          <div className="footer_text" onClick={() => navigate("/about_us")}>
            About Us
          </div>

          <div className="footer_text" onClick={() => navigate("/contact_us")}>
            Contact Us / FAQs
          </div>
          <div className="footer_text" onClick={() => navigate("/privacy_policy")}>
            Privacy & Policy / Terms & Conditions
          </div>
        </div>
        <div className="footer_textright_socials">
          <a className="footer_text_socials" href="https://instagram.com/stitches.socials?igshid=MzRlODBiNWFlZA==" target="_blank">
            Instagram{" "}
          </a>
          <a className="footer_text_socials" href="https://www.facebook.com/profile.php?id=61550463691701" target="_blank">
            Facebook
          </a>
          <a className="footer_text_socials" href="https://www.linkedin.com/company/stitches-socials/" target="_blank">
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
