import React, { useEffect, useState } from "react"
import "./Header.css"
import logo from "../../Assets/logo.svg"
import banner from "../../Assets/banner.svg"
import close from "../../Assets/close.png"

import "./Header.css"
import { useNavigate } from "react-router-dom"
function Header({ position }) {
  const navigate = useNavigate()

  const [clicked, setClicked] = useState("dont")
  useEffect(() => {
    setClicked("dont")
  }, [])
  return (
    <div className="header_main_div" style={{ position: position && "absolute" }}>
      <div
        style={{
          width: "100%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <p
          style={{
            flex: "1 1 0%",
            fontSize: 30,
            fontWeight: "700",
            lineHeight: "30px",
            color: "black",
            letterSpacing: -2,
          }}>
          <img src={logo} width={"180rem"} onClick={() => navigate("/")} style={{ cursor: "pointer" }} className="logo_header" />
        </p>
        <div style={{ flex: "1 1 0%", display: "flex", gap: "2em" }}></div>
        <p
          style={{
            flex: "1 1 0%",
            fontSize: 30,
            lineHeight: "30px",
            textAlign: "right",
            color: "black",
          }}>
          <img src={banner} className="banner_div" onClick={() => setClicked(clicked == true ? false : true)} />
        </p>
      </div>

      <div
        className={
          clicked != "dont" && clicked == true ? "header_menu_div_main" : clicked == false || clicked != "dont" ? "header_menu_div_main_close" : "hide_bar"
        }>
        <div
          className={clicked != "dont" && clicked == true ? "header_menu_div" : clicked == false || clicked != "dont" ? "header_menu_div_close" : "hide_bar"}>
          <img src={close} className="close_banner" onClick={() => setClicked(clicked == true ? false : true)} />
          <div className="header_menu_content">
            <div
              className="header_menu_text_content"
              onClick={() => {
                navigate("/")
                setClicked(false)
              }}>
              Home
            </div>
            <div
              className="header_menu_text_content"
              onClick={() => {
                navigate("/packages")
                setClicked(false)
              }}>
              Packages
            </div>
            <div
              className="header_menu_text_content"
              onClick={() => {
                navigate("/about_us")
                setClicked(false)
              }}>
              About Us
            </div>
         
            <div
              className="header_menu_text_content"
              onClick={() => {
                navigate("/contact_us")
                setClicked(false)
              }}>
              Contact Us
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
