import React from "react"
import notFound from "../../Assets/notFound.png"
import "./NotFound.css"
import { useNavigate } from "react-router-dom"
function NotFound() {
    const navigate =useNavigate()
  return (
    <div className="notFound">
      <img src={notFound} className="notFound_img" />
      <button className="button_main" onClick={()=>navigate("/")} >
        Back To Home
      </button>
    </div>
  )
}

export default NotFound
