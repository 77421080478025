import React from "react";
import "./RadioButton.css";
function RadioButton({ clicked, setClicked, width, disabled }) {
  return (
    <div
      className="radio_button_div"
      style={{
        width: width,
        height: width,
        backgroundColor: clicked ? "var(--main)" : "transparent",
        cursor: disabled ? "default" : "pointer",
      }}
      onClick={() => !disabled && setClicked()}
    ></div>
  );
}

export default RadioButton;
