import React from "react"
import "./AboutUs.css"
import about from "../../Assets/about2.png"
import about2 from "../../Assets/about3.png"
import about3 from "../../Assets/aboutus.png"
import AboutUsComp from "../../Components/AboutUs/AboutUs"

function AboutUs() {
  return (
    <div className="aboutUs_main_div">
      <div className="aboutUs_header_div">
        <AboutUsComp />
      </div>

      <div className="aboutUs_text_div">
        <h1 className="quote_header">
          Welcome to Stitches Socials, <span className="about_header">your vibrant social media hub!</span>
        </h1>
        <div className="aboutUs_content">
          <div className="about_content_text">
            <span style={{ fontFamily: "N_SemiBold" }}>At Stitches Socials,</span> We take pride in being the central force that drives and orchestrates all
            social media activities. With an experienced team spanning diverse industries, propelling creativity, and scaling your brand to new heights with
            every stride we make.
          </div>
          <div className="about_content_text">
            With the support of Stitches Studios, our primary focus revolves around bringing your ideas to life, we ensure seamless integration of design and
            technology, riding the waves of the modern era with confidence.
          </div>
        </div>
        <div className="aboutUs_content2">
          <div className="about_content_text_box" style={{ backgroundColor: "var(--main)" }}>
            <div className="about_content_text_box_header">Our Mission</div>
            Our mission is to craft an unparalleled online presence for our clients, captivating their target audience like never before, and achieving
            remarkable, data-driven results through ingenious social media strategies.
          </div>
          <div className="about_content_text_box" style={{ backgroundColor: "black", color: "white" }}>
            <div className="about_content_text_box_header">Our Vision</div>
            Our vision involves securing a prominent space within the Stitches Studios' four-story building, where we aim to provide an array of cutting-edge
            digitization services.”
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
