import React, { useRef, useState } from "react"
import "./Contact_us.css"
import arrow from "../../Assets/arrow.png"
import { ThreeCircles } from "react-loader-spinner"
import emailjs from "@emailjs/browser"
import ReCAPTCHA from "react-google-recaptcha"

function Contact_us() {
  const [data, setData] = useState({})
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [recaptchaCheck, setRecaptchaCheck] = useState(false)
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()
    setMessage("")

    if (data?.name == "" || data?.email == "" || data?.phone == "" || data?.message == "") setMessage("Please fill all the fields")
    else {
      var templateParams = {
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        message: data?.message,
      }
      setLoading(true)

      emailjs.send("service_p131sn9", "template_o40f28o", templateParams, "7nwgzudExB_Hk07S-").then(
        (result) => {
          setLoading(false)
          setMessage("Email sent successfully!")
          setData({
            name: "",
            email: "",
            phone: "",
            message: "",
          })
          form.current.reset()
        },
        (error) => {
          setMessage("Something went wrong!")
        },
      )
    }
  }

  const faq = [
    {
      content: "General",
      subContent: [
        {
          title: "Who are we? ",
          body: `We are a vibrant social media hub committed to enhancing your online presence, fostering meaningful connections with your target audience, and delivering measurable and impactful results to boost your business, through a well-crafted "Social Media Strategy"`,
        },
        {
          title: "How do I initiate the process and get started?",
          body: "The process begins with an all-inclusive consultation. You're welcome to explore our website and schedule a complimentary session for brainstorming at your convenience. During this session, our primary aim is to grasp your objectives, target audience, and business goals. With this understanding, we then craft a personalized strategy and put forth the most fitting package options for your consideration.",
        },
        {
          title: "Which country are you based in?",
          body: "We take pride in our Lebanese roots while extending our expert social media solutions to clients worldwide.",
        },
        {
          title: "What advantages can my business gain from Social Media?",
          body: "Incorporating social media into your business strategy can unlock a myriad of benefits, enabling you to increase brand awareness, engage with you audience, foster brand loyalty, drive website traffic, generate leads, and ultimately catalyzing sales growth.",
        },
        {
          title: "Do you engage with enterprises of diverse sizes?",
          body: "Absolutely, we serve businesses spanning the spectrum of sizes, encompassing startups to well-established enterprises. Our strategies are versatile, tailored to accommodate your distinct requirements.",
        },
        {
          title: "Will I be granted access to my social media accounts?",
          body: "Certainly! You will maintain complete access to your social media accounts. Our collaboration involves close coordination, and any modifications are subject to your approval before being executed.",
        },
      ],
    },
    {
      content: "3D Booster Plan",
      subContent: [
        {
          title: "What does the 3D Booster Plan include?",
          body: "Our 3D Booster Plan by Stitches Studios encompasses regular creation of lifelike 3D visuals, integration of diverse content formats including images and videos, interactive product showcases, platform-optimized visuals, audience engagement strategies, and ongoing refinement based on feedback and analytics.",
        },
        {
          title: "Can I provide input on the 3D content creation process?",
          body: "Absolutely, we value your input. Our collaborative approach allows you to provide feedback and direction to ensure that the 3D visuals align with your vision and objectives.",
        },
        {
          title: "Can I track the effectiveness of the 3D content?",
          body: "Yes, we provide analytics to help you monitor the performance of the 3D content, allowing you to gauge engagement and make informed decisions.",
        },
      ],
    },
    {
      content: "Packages",
      subContent: [
        {
          title: "How often are the Stories and Posts updated?",
          body: "With the Basic Monthly Package, you'll receive fresh content every month, ensuring consistent engagement with your audience.",
        },
        {
          title: "Can I customize the type of content included in the package?",
          body: "Absolutely, while the package offers a diverse array of content types, we can tailor it to align with your brand's specific needs and preferences.",
        },
        {
          title: "Is it possible to modify my package at any time?",
          body: "You retain the flexibility to upgrade, downgrade, or make adjustments to your plan based on your changing business requirements, once the agreed contract period concludes.",
        },
        {
          title: 'What is "Monthly Reporting" and "Analytics"?',
          body: "At the end of each month, we provide detailed reports and analytics that offer insights into your social media performance. These metrics help you track progress, identify successful strategies, and refine future content to enhance your social media presence.",
        },
      ],
    },
    {
      content: "Payment Methods",
      subContent: [
        {
          title: "What payment methods are accepted?",
          body: "We provide flexible payment solutions, accepting various methods including cash payment, whether conducted in person or through Whish or OMT services.",
        },
        {
          title: "Are there any additional fees associated with specific payment methods?",
          body: "In most cases, our accepted payment methods do not incur additional fees. However, please refer to PSP or contact our support team for specific details.",
        },
        {
          title: "How do modifications to my package affect the billing process?",
          body: "If you choose to make changes to your plan, these adjustments will be factored into the upcoming billing cycle. Charges will be prorated to maintain accuracy.",
        },
        {
          title: "Are cryptocurrency payments accepted?",
          body: "Currently, we do not accept cryptocurrency payments.",
        },
      ],
    },
  ]
  const onChange = () => {
    setRecaptchaCheck(true)
  }
  const onExpired = () => {
    setRecaptchaCheck(false)
  }

  const [selectedContent, setSelectedContent] = useState(0)
  const [selectedSubContent, setSelectedSubContent] = useState()

  const [mobileSelectedFAQ, setMobileSelectedFAQ] = useState()
  const [mobileSelectedFAQSubcontent, setMobileSelectedFAQSubcontent] = useState()

  return (
    <div className="contactUs_Main">
      <div className="contactUs_top">
        <div className="contactUs_top_left">
          <div className="contactUs_top_left_top">
            <div className="contactUs_top_left_top_text1">
              LET'S <br className="breakline" />
              GET IN
              <br className="breakline" /> TOUCH
            </div>
            <div className="contactUs_top_left_top_text2">
              LET'S <br className="breakline" />
              GET IN
              <br className="breakline" /> TOUCH
            </div>
          </div>
          <div className="contactUs_top_left_bottom">
            <p
              style={{
                flex: "1 1 0%",
                height: "100%",
                fontSize: "1.2rem",
                lineHeight: "1.5em",
                color: "black",
              }}>
              <b style={{ fontFamily: "N_Bold" }}>We Are A Social Media Agency</b>
              <br />
              A Company by Stitches Studios
              <br />
              <div style={{ display: "flex", flexDirection: "column", float: "left" }}>
                <hr style={{ border: "2px solid black", width: "6rem", zIndex: "2" }} />

                <hr
                  style={{
                    border: "3px solid #d6ff00",
                    width: "6rem",
                    marginTop: "-0.8rem",
                  }}
                />
              </div>
              <b></b>
            </p>
          </div>
        </div>
        <div className="contactUs_top_right">
          <form className="form_main" ref={form} onSubmit={(e) => sendEmail(e)}>
            <div>
              <input
                className="input_main input_field"
                placeholder="Full Name"
                required
                value={data?.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>
            <div className="Form_middle">
              <input
                className="input_main input_field"
                placeholder="Email"
                type="email"
                required
                value={data?.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
              <input
                className="input_main input_field"
                placeholder="Phone Number"
                required
                value={data?.phone}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
              />
            </div>
            <div>
              <textarea
                rows={5}
                className="input_main input_field"
                placeholder="Message"
                required
                value={data?.message}
                onChange={(e) => setData({ ...data, message: e.target.value })}
              />
            </div>
            {recaptchaCheck ? (
              <button className="button_main">
                {loading ? (
                  <ThreeCircles
                    height="40"
                    width="40"
                    color="black"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                  />
                ) : (
                  "Submit"
                )}
              </button>
            ) : (
              <button disabled className="button_main">
                {loading ? (
                  <ThreeCircles
                    height="40"
                    width="40"
                    color="black"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                  />
                ) : (
                  "Submit"
                )}
              </button>
            )}

            <div className="message">{message}</div>
            {/* <ReCAPTCHA sitekey="6LeB6M4nAAAAACTQVG8dYHWi41zendnqwpKpz9sI" onChange={onChange} onExpired={onExpired} /> */}
            <ReCAPTCHA sitekey="6Lc7G88nAAAAAOsH_oGMIUxj5KIOPDkyB2dGAySd" onChange={onChange} onExpired={onExpired} />
          </form>
          {/* <div className="contactUs_top_right_bottom">
            <div>
              Call: <a href="tel:0096171688632">00961 71 688 632</a>
            </div>
            <div>
              Email: <a href="mailto:us@stitchessocials.com">us@stitchessocials.com</a>
            </div>
          </div> */}
        </div>
      </div>
      <div className="contactUs_top_bot">
        <div className="contactUs_top_bot_left">
          <p
            style={{
              flex: "1 1 0%",
              height: "100%",
              fontSize: "1.2rem",
              lineHeight: "1.5em",
              color: "black",
            }}>
            <b style={{ fontFamily: "N_Bold" }}>We Are A Social Media Agency</b>
            <br />
            A Company by Stitches Studios
            <br />
            <div style={{ display: "flex", flexDirection: "column", float: "left" }}>
              <hr style={{ border: "2px solid black", width: "6rem", zIndex: "2" }} />

              <hr
                style={{
                  border: "3px solid #d6ff00",
                  width: "6rem",
                  marginTop: "-0.8rem",
                }}
              />
            </div>
            <b></b>
          </p>
        </div>
        <div className="contactUs_top_bot_right">
          <div>
            Call: <a href="tel:0096171688632">00961 71 688 632</a>
          </div>
          <div>
            Email: <a href="mailto:us@stitchessocials.com">us@stitchessocials.com</a>
          </div>
        </div>
      </div>
      <div className="FAQ">
        <div className="FAQ_title">
          <div className="FAQ_title_text1">Frequently Asked Questions</div>
          <div className="FAQ_title_text2">Frequently Asked Questions</div>
        </div>
        <div className="FAQ_main">
          <div className="FAQ_main_left">
            <div className="FAQ_main_left_title">Table of Content</div>
            <div className="FAQ_main_left_content">
              {faq.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={index === selectedContent ? "FAQ_main_left_content_item selectedItem " : "FAQ_main_left_content_item"}
                    onClick={() => setSelectedContent(index)}>
                    {item.content}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="FAQ_horizontal_line"></div>
          <div className="FAQ_main_right">
            {faq[selectedContent].subContent.length > 0 &&
              faq[selectedContent].subContent.map((content, index) => {
                return (
                  <div
                    key={index}
                    className={selectedSubContent === index ? "faq_subcontent_main selectedSubContent" : "faq_subcontent_main"}
                    onClick={() => {
                      if (selectedSubContent === index) {
                        setSelectedSubContent()
                      } else {
                        setSelectedSubContent(index)
                      }
                    }}>
                    <div>
                      <div className={selectedSubContent === index ? "faq_subcontent_main_title selectedSubContent_title" : "faq_subcontent_main_title"}>
                        {content.title}
                      </div>
                      <div className={selectedSubContent === index ? "faq_subcontent_main_body selectedSubContent_body" : "faq_subcontent_main_body"}>
                        {content.body}
                      </div>
                    </div>
                    <div>
                      <img src={arrow} className={selectedSubContent === index ? "arrow_img selected_Img" : "arrow_img"} />
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <div className="FAQ_mobile">
        <div className="FAQ_mobile_title">Frequently Asked Questions</div>
        <div className="FAQ_mobile_content">
          {faq.map((item, index1) => {
            return (
              <div className="FAQ_mobile_main" key={index1}>
                <div className="FAQ_mobile_main_title">&#9679;&nbsp;{item.content}</div>
                {item.subContent.length > 0 &&
                  item.subContent.map((content, index2) => {
                    return (
                      <div
                        className={
                          mobileSelectedFAQ === index1 && mobileSelectedFAQSubcontent === index2
                            ? "FAQ_mobile_main_subcontent selected_FAQ_mobile_main_subcontent"
                            : "FAQ_mobile_main_subcontent"
                        }
                        key={index2}
                        onClick={() => {
                          if (mobileSelectedFAQ === index1 && mobileSelectedFAQSubcontent === index2) {
                            setMobileSelectedFAQ()
                            setMobileSelectedFAQSubcontent()
                          } else {
                            setMobileSelectedFAQ(index1)
                            setMobileSelectedFAQSubcontent(index2)
                          }
                        }}>
                        <div>
                          <div
                            className={
                              mobileSelectedFAQ === index1 && mobileSelectedFAQSubcontent === index2
                                ? "FAQ_mobile_main_subcontent_title selected_FAQ_mobile_main_subcontent_title"
                                : "FAQ_mobile_main_subcontent_title"
                            }>
                            {content.title}
                          </div>
                          <div
                            className={
                              mobileSelectedFAQ === index1 && mobileSelectedFAQSubcontent === index2
                                ? "FAQ_mobile_main_subcontent_body selected_FAQ_mobile_main_subcontent_body"
                                : "FAQ_mobile_main_subcontent_body"
                            }>
                            {content.body}
                          </div>
                        </div>
                        <img
                          src={arrow}
                          className={
                            mobileSelectedFAQ === index1 && mobileSelectedFAQSubcontent === index2 ? "mobile_arrow_img selected_mobile_img" : "mobile_arrow_img"
                          }
                        />
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Contact_us
