import logo from "../../Assets/logo.svg"
import banner from "../../Assets/banner.svg"
import { useNavigate } from "react-router-dom"

export function Underlay() {
  const navigate = useNavigate()
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: "20px 50px",
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        pointerEvents: "none",
        zIndex: 1,
      }}
      className="landing_main">
  
      <div style={{ height: 120 }} className="margin_div_landing" />
      <div
        style={{
          width: "100%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
        }}>
        <p
          style={{
            flex: "1 1 0%",
            height: "100%",
            fontSize: "1.2rem",
            lineHeight: "1.5em",
            color: "black",
            marginTop: "4rem",
          }}>
          <b style={{ fontFamily: "N_Bold" }}>We Are A Social Media Agency</b>
          <br />
          A Company by Stitches Studios
          <br />
          <div style={{ display: "flex", flexDirection: "column", float: "left" }}>
            <hr style={{ border: "3px solid black", width: "6rem" }} />

            <hr
              style={{
                border: "3px solid #d6ff00",
                width: "6rem",
                marginTop: "-0.7rem",
              }}
            />
          </div>
          <b></b>
        </p>
        <div style={{ width: 10 }} className="full" />
        <p
          style={{
            transform: "rotate3d(0, 0, 1, 90deg) translate3d(100%,10px,0)",
            transformOrigin: "right",
            fontSize: "1rem",
            lineHeight: "100%",
            textAlign: "right",
            color: "black",
            whiteSpace: "nowrap",
            fontFamily: "N_Bold",
          }}
          className="full">
          DRAG YOUR POINTER &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ●
        </p>
      </div>
      <div style={{ height: 10 }} />
      <div
        className="full"
        style={{
          fontFamily: "'Antonio', sans-serif",
          width: "100%",
          flex: "1 1 0%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          marginBottom: "-2rem",
          justifyContent: "center",
        }}>
        <p
          style={{
            flex: "1 1 0%",
            fontSize: 250,
            lineHeight: "1em",
            color: "black",
            margin: 0,
            fontFamily: "N_Bold",
          }}>
          X
        </p>
        <div style={{ width: 10 }} />
        <div style={{ position: "relative" }}>
          <p
            style={{
              flex: "1 1 0%",
              fontSize: 250,
              lineHeight: "100%",
              textAlign: "right",
              color: "transparent",
              margin: 0,
              letterSpacing: -10,
              fontFamily: "N_Bold",
              WebkitTextStroke: "6px #d6ff00",
              marginTop: "0.6rem",
              marginLeft: "0.5rem",
            }}>
            -23
          </p>
          <p
            style={{
              flex: "1 1 0%",
              fontSize: 250,
              lineHeight: "100%",
              textAlign: "right",
              color: "black",
              margin: 0,
              letterSpacing: -10,
              fontFamily: "N_Bold",
              position: "absolute",
              top: "0",
            }}>
            -23
          </p>
        </div>
      </div>
      <div />
      <div
        style={{
          pointerEvents: "all",
          pointer: "auto",
          width: "100%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "center",
        }} 
        className="button_landing_divv"
        >
        <a
          className="full"
          style={{
            whiteSpace: "nowrap",
            flex: "1 1 0%",
            fontSize: 12,
            lineHeight: "1.5em",
            color: "black",
          }} href="https://www.google.com/maps/search/?api=1&query=33.930328,35.586838" target="_blank"> 
          <b>Designing Connections</b>
          <br />
          33.930328, 35.586838
        </a>
        <div style={{ width: 10 }} />
        <p
          style={{
            fontFamily: "'Antonio', sans-serif",
            flex: "1 1 0%",
            fontSize: 16,
            fontWeight: "700",
            lineHeight: "1em",
            textAlign: "center",
            color: "black",
            letterSpacing: -0.5,
            whiteSpace: "nowrap",
          }}>
          <button
            className="button_landing"
            onClick={() => navigate("/packages")}
            style={{
              border: "none",
              background: "#d6ff00",
              color: "black",
              cursor: "pointer",
              padding: "1.5rem 3rem",
              fontSize: "1.5rem",
              fontFamily: "N_Bold",
            }}>
            Start Here{" "}
          </button>
        </p>
        <div style={{ width: 10 }} />
        <p
          className="full"
          style={{
            flex: "1 1 0%",
            fontSize: 12,
            lineHeight: "1em",
            textAlign: "right",
            color: "black",
          }}></p>
      </div>
    </div>
  )
}

export function Overlay() {
  return (
    <div style={{ position: "absolute", bottom: 40, right: 40, zIndex: 2000 }} className="overlay_div">
      <p
        style={{
          flex: "1 1 0%",
          fontSize: 12,
          lineHeight: "1em",
          textAlign: "right",
          color: "black",
        }}>
        <a href="https://instagram.com/stitches.socials?igshid=MzRlODBiNWFlZA==" target="_blank">Instagram</a> <a href="https://www.facebook.com/profile.php?id=61550463691701" target="_blank">Facebook</a> <a href="https://www.linkedin.com/company/stitches-socials/"  target="_blank">LinkedIn</a>
      </p>
    </div>
  )
}
