import React, { useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import Landing from "./Pages/Landing/Landing"
import Quote from "./Pages/Quote/Quote"
import { Underlay } from "./Pages/Landing/LandingContent"
import Header from "./Components/Header/Header"
import AboutUs from "./Pages/AboutUs/AboutUs"
import Footer from "./Components/Footer/Footer"
import Contact_us from "./Pages/Contact_us/Contact_us"
import NotFound from "./Components/NotFound/NotFound"
import Privacy from "./Pages/Privacy/Privacy"
function AllRoutes() {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <div>
      {pathname != "/" && <Header />}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/packages" element={<Quote />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/privacy_policy" element={<Privacy />} />


        <Route path="/contact_us" element={<Contact_us />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {pathname != "/" && <Footer />}
    </div>
  )
}

export default AllRoutes
