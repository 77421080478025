import React, { Suspense } from "react";
import { Overlay, Underlay } from "./LandingContent";
import { LandingAnimation } from "./LandingAnimation";
import "./Landing.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
function Landing() {
  return (
    <div className="landing_main_div">
      <Header position={true}/>
      <Underlay />
      <Suspense>
        <LandingAnimation />
      </Suspense>
      <Overlay />
    </div>
  );
}

export default Landing;
