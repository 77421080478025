import React from "react";
import "./Addons.css";
import RadioButton from "../RadioButton/RadioButton";
function Addons({ title, info, price, clicked, setClicked }) {
  return (
    <div className="addon_main_box">
      <div className="addon_left_div">
        <div className="addon_title">{title}</div>
        <div className="addon_info">{info}</div>
      </div>
      <div className="addon_right_div">
        <RadioButton clicked={clicked} setClicked={setClicked} width="2rem" />
        <div className="addon_price">
          <span>Starting</span>
          <div className="addon_price_info">{price}</div>
        </div>
      </div>
    </div>
  );
}

export default Addons;
