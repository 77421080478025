import React, { useEffect, useState } from "react"
import "./Quote.css"
import RadioButton from "../../Components/RadioButton/RadioButton"
import Addons from "../../Components/Addons/Addons"
import FormQuote from "../../Components/FormQuote/FormQuote"
import arrow from "../../Assets/arrow_2.svg"
import gsap from "gsap"

function Quote() {
  const [monthlyPack, setMonthlyPack] = useState(true)
  const [addon1, setAddon1] = useState(false)
  const [addon2, setAddon2] = useState(false)
  const [addon3, setAddon3] = useState(false)
  const [addon4, setAddon4] = useState(false)
  const [addon5, setAddon5] = useState(false)
  const [addon6, setAddon6] = useState(false)
  const [addon7, setAddon7] = useState(false)
  const [quote, setQuote] = useState([])
  const [Total, setTotal] = useState(1000)
  const [data, setData] = useState({})

  const handleAdd = (item) => {
    setQuote([...quote, item])
    setTotal(Total + item.price)
  }

  const handleRemove = (item) => {
    setQuote(quote.filter((el) => el.title !== item.title))
    setTotal(Total - item.price)
  }

  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (clicked) {
      gsap.to(".arrow_img_mobile", {
        duration: 0.5,
        transform: "rotateX(180deg)",
      })
      if (window.innerWidth <= 768) {
        gsap.to(".quick_quote_div", {
          duration: 0.5,
          height: "0vh",
          minHeight: "0vh",
          opacity: 0,
          padding: "0px",
          margin: "0px",
          visibility: "hidden",
        })
      }
    } else {
      gsap.to(".arrow_img_mobile", {
        duration: 0.5,
        transform: "rotateX(0deg)",
      })
      if (window.innerWidth <= 768) {
        gsap.to(".quick_quote_div", {
          duration: 0.5,
          height: "auto",
          minHeight: "10vh",
          opacity: 1,
          padding: "0px",
          margin: "0px",
          visibility: "visible",
        })
      }
    }
  }, [clicked])

  return (
    <div className="quote_main_div">
      <div className="quote_left_div">
        <div className="quote_left_div_top">
          <div className="quote_header">Build Your Package</div>
          <div className="package_box_main">
            <div className="package_box_upper">
              <div className="package_left_div">
                <div className="package_header_div">Basic Monthly Package</div>
                <div className="package_Subheader_div">The Perfect package to help you get started</div>
              </div>
              <div className="package_right_div">
                <RadioButton disabled={true} clicked={monthlyPack} setClicked={() => setMonthlyPack(monthlyPack)} width="2rem" />
              </div>
            </div>
            <div className="package_box_second">
              <div className="package_desc_div">
                <div className="package_dec_info">- Short Videos</div>
                <div className="package_dec_info">- Animated Graphics</div>
                <div className="package_dec_info">- Interactive Features</div>
                <div className="package_dec_info">- User-Generated Content (UGC)</div>
              </div>
              <div className="package_desc_div">
                <div className="package_dec_info">- Behind-the-Scenes Footage</div>
                <div className="package_dec_info">- Contests and Giveaways</div>
                <div className="package_dec_info">- Product Launch Teasers</div>
                <div className="package_dec_info">- Reporting and Analytics</div>
              </div>
            </div>
            <div className="package_box_third">
              <div className="package_summary">
                <div className="package_summary_header">12 Stories</div>

                <div className="package_summary_info">Still & Animation</div>
              </div>
              <div className="package_summary">
                <div className="package_summary_header">12 Posts</div>

                <div className="package_summary_info">1:1 & Reels</div>
              </div>
            </div>
          </div>
        </div>
        <div className="quote_left_addons">
          <div className="quote_header2">Addons</div>
          <Addons
            title="3D Booster Pack - Powered By Stitches Studios"
            info="Elevate your brand's digital presence with our 3D Booster Pack. Get lifelike 3D visuals for up to 2 products every month, complemented by 3 content visuals, including both stills and videos. Stand out and captivate your audience with unparalleled three-dimensional finesse."
            price="$1,150"
            setClicked={() => {
              setAddon2(!addon2)
              if (addon2) {
                handleRemove({ title: "3D Booster Pack", price: 1150 })
              } else {
                handleAdd({ title: "3D Booster Pack", price: 1150 })
              }
            }}
            clicked={addon2}
          />
          <Addons
            title="Engagement Addon"
            info="Enhance your brand's engagement by implementing a strategic plan to respond to comments and interactions effectively. This approach fosters meaningful conversations with your audience, driving increased interest and loyalty."
            price="$150"
            setClicked={() => {
              setAddon3(!addon3)

              if (addon3) {
                handleRemove({ title: "Engagement Addon", price: 150 })
              } else {
                handleAdd({ title: "Engagement Addon", price: 150 })
              }
            }}
            clicked={addon3}
          />
          <Addons
            title="Professional Photography and Post-Production Services"
            info="Tailored to your brand's unique identity, our professional shooting and editing services ensure visually stunning content that resonates with your target personas, leaving a lasting impact on your audience. (Content for Campaigns and Socials - 1 Month)."
            price="$300"
            setClicked={() => {
              setAddon4(!addon4)

              if (addon4) {
                handleRemove({
                  title: "Professional Photography",
                  price: 300,
                })
              } else {
                handleAdd({
                  title: "Professional Photography",
                  price: 300,
                })
              }
            }}
            clicked={addon4}
          />
          <Addons
            title="Augmented Reality Filters for Instagram"
            info="Elevate your content with interactive and immersive experiences using augmented reality filters. These cutting-edge filters add an exciting and playful dimension to your social media posts, leaving your audience captivated."
            price="$550"
            setClicked={() => {
              setAddon5(!addon5)

              if (addon5) {
                handleRemove({
                  title: "Augmented Reality Filters",
                  price: 550,
                })
              } else {
                handleAdd({
                  title: "Augmented Reality Filters",
                  price: 550,
                })
              }
            }}
            clicked={addon5}
          />
          <Addons
            title="AI Chat-Bot Integration"
            info="Embrace the future of customer support with AI-powered chat-bots seamlessly integrated into your social media platforms. Our advanced chat-bot solutions cover Instagram, Facebook, website messages, and more."
            price="$350"
            setClicked={() => {
              setAddon6(!addon6)

              if (addon6) {
                handleRemove({
                  title: "AI Chat-Bot",
                  price: 350,
                })
              } else {
                handleAdd({
                  title: "AI Chat-Bot",
                  price: 350,
                })
              }
            }}
            clicked={addon6}
          />{" "}
          <Addons
            title="Social Automation"
            info="Enhance your online presence with our automation add-on. Automate content uploads, schedule optimal posting times, and deliver tailored news, ensuring consistent engagement and efficiency for your brand."
            price="$650"
            setClicked={() => {
              setAddon7(!addon7)

              if (addon7) {
                handleRemove({
                  title: "Social Automation",
                  price: 650,
                })
              } else {
                handleAdd({
                  title: "Social Automation",
                  price: 650,
                })
              }
            }}
            clicked={addon7}
          />
        </div>
      </div>
      <div
        className="quote_right_div_top_mobile"
        onClick={() => {
          setClicked(!clicked)
        }}>
        <div className="quote_header_mobile">
          <div className="quote_header_mobile_text">
            Your Quote <img src={arrow} className={"arrow_img_mobile"} />
          </div>
          <div className="quick_quote_div_total_right">
            {Total.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            })}
          </div>{" "}
        </div>
        <div className={"quick_quote_div"}>
          <div classsName="quick_quote_div_top">
            <div className="quick_quote_div_total">
              <div className="quick_quote_div_total_left">Basic Monthly Package</div>
              <div className="quick_quote_div_total_right">$1,000</div>
            </div>
            <div className="addons_div_quote_addons">
              {quote.length > 0 ? (
                quote.map((item) => (
                  <div className="quick_quote_div_total">
                    <div className="quick_quote_div_total_left_sub">{item.title}</div>
                    <div className="quick_quote_div_total_right_sub">
                      {item.price.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                      })}
                    </div>
                  </div>
                ))
              ) : (
                <div className="quick_quote_div_total">
                  <div className="quick_quote_div_total_left_sub">No Addons Selected</div>
                  <div className="quick_quote_div_total_right_sub">$0</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="next_button_parent">
          <button className="next_button" onClick={(e) => e.stopPropagation()}>
            <a href="#sss">Next</a>
          </button>
        </div>
      </div>

      <div className="quote_right_div">
        <div className="quote_right_div_top">
          <div className="quote_header">Your Quick Quote</div>
          <div className="quick_quote_div">
            <div classsName="quick_quote_div_top">
              <div className="quick_quote_div_total">
                <div className="quick_quote_div_total_left">Basic Monthly Package</div>
                <div className="quick_quote_div_total_right">$1,000</div>
              </div>
              <div className="addons_div_quote_addons">
                {quote.length > 0 ? (
                  quote.map((item) => (
                    <div className="quick_quote_div_total">
                      <div className="quick_quote_div_total_left_sub">{item.title}</div>
                      <div className="quick_quote_div_total_right_sub">
                        {item.price.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                        })}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="quick_quote_div_total">
                    <div className="quick_quote_div_total_left_sub">No Addons Selected</div>
                    <div className="quick_quote_div_total_right_sub">$0</div>
                  </div>
                )}
              </div>
            </div>
            <div className="quick_quote_div_total">
              <div className="quick_quote_div_total_left">Your Estimated Total Per Month</div>
              <div className="quick_quote_div_total_right">
                {Total.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="quote_right_div_secondry" id="sss">
          <div className="quote_header2">Set up a quick 30 min discovery call</div>
          <button className="button_main" onClick={() => window.open("https://calendly.com/stitches-socials/unlocking_conversations")}>
            Book A Time With Us
          </button>
        </div>
        <div className="quote_right_div_bottom">
          <div className="quote_header2">OR</div>
          <FormQuote setData={setData} data={data} quote={quote} />
        </div>
      </div>
    </div>
  )
}

export default Quote
