import React, { useEffect, useRef, useState } from "react"
import "./FormQuote.css"
import RadioButton from "../RadioButton/RadioButton"
import emailjs from "@emailjs/browser"
import { ThreeCircles } from "react-loader-spinner"
import ReCAPTCHA from "react-google-recaptcha"

function FormQuote({ data, setData, quote }) {
  const [message, setMessage] = useState("")
  const form = useRef()
  const [loading, setLoading] = useState(false)
  const [recaptchaCheck, setRecaptchaCheck] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault()
    setMessage("")

    if (
      data?.fName == "" ||
      data?.lName == "" ||
      data?.email == "" ||
      data?.phone == "" ||
      data?.company == "" ||
      data?.industry == "" ||
      data?.link == "" ||
      !data?.goals ||
      !data?.help ||
      !data?.marketing ||
      !data?.find
    )
      setMessage("Please fill all the fields")
    else {
      var templateParams = {
        name: data?.fName + " " + data?.lName,
        email: data?.email,
        phone: data?.phone,
        company: data?.company,
        industry: data?.industry,
        url: data?.link,
        goals: data?.goals == 1 ? "Help me launch" : data?.goals == 2 ? "Help me hit my goals" : "Help me scale",
        service: data?.help == 1 ? "Advertising Creative" : data?.help == 2 ? "Campaign Building" : "Social Automation",
        budget: data?.marketing == 1 ? "$0 - $5,000" : data?.marketing == 2 ? "$5,000 - $15,000" : data?.marketing == 3 ? "$15,000 - $50,000" : "$50,000+",
        source: data?.find == 1 ? "Referral" : data?.find == 2 ? "Google" : data?.find == 3 ? "Social Media" : "Other",
        package: quote && quote.length > 0 ? quote.map((item) => item?.title + " " + item?.price).join(", ") : "Not Selected",
      }
      setLoading(true)

      emailjs.send("service_p131sn9", "template_f65xjs4", templateParams, "7nwgzudExB_Hk07S-").then(
        (result) => {
          setLoading(false)
          setMessage("Email sent successfully!")
          setData({
            fName: "",
            lName: "",
            email: "",
            phone: "",
            company: "",
            industry: "",
            link: "",
            goals: "",
            help: "",
            marketing: "",
            find: "",
            quote: [],
          })
          form.current.reset()
        },
        (error) => {
          setMessage("Something went wrong!")
        },
      )
    }
  }
  const onChange = () => {
    setRecaptchaCheck(true)
  }
  const onExpired = () => {
    setRecaptchaCheck(false)
  }

  return (
    <div className="form_quota_main_div">
      <form className="form_quota_form" ref={form} onSubmit={(e) => sendEmail(e)}>
        <div className="input_flex">
          <input
            name="from_fName"
            required
            className="input_main"
            type="text"
            placeholder="First Name"
            value={data?.fName}
            onChange={(e) => setData({ ...data, fName: e.target.value })}
          />
          <input
            name="from_lName"
            required
            className="input_main"
            type="text"
            placeholder="Last Name"
            value={data?.lName}
            onChange={(e) => setData({ ...data, lName: e.target.value })}
          />
        </div>
        <div className="input_flex">
          <input
            required
            name="from_email"
            className="input_main"
            type="email"
            placeholder="Email"
            value={data?.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <input
            name="from_phone"
            required
            className="input_main"
            type="phone"
            placeholder="Phone Number"
            value={data?.phone}
            onChange={(e) => setData({ ...data, phone: e.target.value })}
          />
        </div>
        <input
          name="from_company"
          required
          className="input_main"
          type="text"
          placeholder="Company Name"
          value={data?.company}
          onChange={(e) => setData({ ...data, company: e.target.value })}
        />
        <input
          name="from_industry"
          required
          className="input_main"
          type="text"
          placeholder="Industry"
          value={data?.industry}
          onChange={(e) => setData({ ...data, industry: e.target.value })}
        />
        <input
          name="from_link"
          className="input_main"
          type="link"
          placeholder="Website Link (Optional)"
          value={data?.link}
          onChange={(e) => setData({ ...data, link: e.target.value })}
        />
        <div className="form_quota_radio_div" name="message">
          <div className="form_quota_radio_main">
            <div className="radio_title">Your Business Goals</div>
            <div className="form_quota_radio_comp">
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.goals == 1 ? true : false} setClicked={() => setData({ ...data, goals: 1 })} />
                Help me launch
              </div>
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.goals == 2 ? true : false} setClicked={() => setData({ ...data, goals: 2 })} />
                Help me hit my goals
              </div>{" "}
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.goals == 3 ? true : false} setClicked={() => setData({ ...data, goals: 3 })} />
                Help me scale
              </div>
            </div>
          </div>
          <div className="form_quota_radio_main">
            <div className="radio_title">How Can We help?</div>
            <div className="form_quota_radio_comp">
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.help == 1 ? true : false} setClicked={() => setData({ ...data, help: 1 })} />
                Advertising Creative
              </div>
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.help == 2 ? true : false} setClicked={() => setData({ ...data, help: 2 })} />
                Campaign Building
              </div>{" "}
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.help == 3 ? true : false} setClicked={() => setData({ ...data, help: 3 })} />
                Social Automation
              </div>
            </div>
          </div>
          <div className="form_quota_radio_main">
            <div className="radio_title">Monthly Marketing Budget</div>
            <div className="form_quota_radio_comp">
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.marketing == 1 ? true : false} setClicked={() => setData({ ...data, marketing: 1 })} />
                $0 - $5,000
              </div>
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.marketing == 2 ? true : false} setClicked={() => setData({ ...data, marketing: 2 })} />
                $5,000 - $15,000
              </div>{" "}
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.marketing == 3 ? true : false} setClicked={() => setData({ ...data, marketing: 3 })} />
                $15,000 - $50,000
              </div>
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.marketing == 4 ? true : false} setClicked={() => setData({ ...data, marketing: 4 })} />
                $50,000 +
              </div>
            </div>
          </div>{" "}
          <div className="form_quota_radio_main">
            <div className="radio_title">How Did You Find Us?</div>
            <div className="form_quota_radio_comp">
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.find == 1 ? true : false} setClicked={() => setData({ ...data, find: 1 })} />
                Referral
              </div>
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.find == 2 ? true : false} setClicked={() => setData({ ...data, find: 2 })} />
                Google
              </div>{" "}
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.find == 3 ? true : false} setClicked={() => setData({ ...data, find: 3 })} />
                Social Media
              </div>
              <div className="radio_flex">
                <RadioButton width="1.5rem" clicked={data?.find == 4 ? true : false} setClicked={() => setData({ ...data, find: 4 })} />
                Other
              </div>
            </div>
          </div>
        </div>

        {recaptchaCheck ? (
          <button className="button_main">
            {loading ? (
              <ThreeCircles
                height="40"
                width="40"
                color="black"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
            ) : (
              "Submit"
            )}
          </button>
        ) : (
          <button disabled className="button_main">
            {loading ? (
              <ThreeCircles
                height="40"
                width="40"
                color="black"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
            ) : (
              "Submit"
            )}
          </button>
        )}
        <div className="message">{message}</div>
        {/* <ReCAPTCHA sitekey="6LeB6M4nAAAAACTQVG8dYHWi41zendnqwpKpz9sI" onChange={onChange} onExpired={onExpired} /> */}
        <ReCAPTCHA sitekey="6Lc7G88nAAAAAOsH_oGMIUxj5KIOPDkyB2dGAySd" onChange={onChange} onExpired={onExpired} />
      </form>
    </div>
  )
}

export default FormQuote
